import { animate, inView } from 'motion';

import { throttle } from '@webentorCore/_utils';

class ScrollSnap {
  constructor() {
    this.sections = document.querySelectorAll('.js-animated-section');
    this.isScrolling = false;
    this.currentSection = null;
    this.lastScrollY = window.scrollY;
    this.init();
  }

  init() {
    // this.setupDefaultAnimation();

    // Wait for any initial animations/loading
    setTimeout(() => {
      // Scroll to top of page
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
      if (typeof window.scrollTo !== 'function') {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
      this.checkVisibleSection(true);
    }, 100);

    window.addEventListener(
      'scroll',
      throttle(() => {
        if (!this.isScrolling) {
          window.requestAnimationFrame(() => {
            this.checkVisibleSection();
          });
        }
      }, 20),
    );
  }

  checkVisibleSection(isInitial = false) {
    // Use visualViewport for mobile browsers if available, fallback to innerHeight
    const viewportHeight = window.visualViewport?.height || window.innerHeight;
    const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
    const scrollTop = window.scrollY;
    const scrollingDown = scrollTop > this.lastScrollY;
    const threshold = 'ontouchstart' in window ? 0.2 : 0.2;
    const thresholdPx = 'ontouchstart' in window ? 200 : 300;

    this.sections.forEach((section) => {
      const rect = section.getBoundingClientRect();

      const visiblePx =
        Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);

      const isNewSection = this.currentSection !== section;
      const isScrollingIntoView = scrollingDown
        ? rect.top < viewportHeight * (1 - threshold) && rect.top > 0
        : rect.bottom > viewportHeight * threshold &&
          rect.bottom < viewportHeight;

      if (
        visiblePx >= thresholdPx &&
        isNewSection &&
        (isScrollingIntoView || isInitial)
      ) {
        if (this.currentSection) {
          document.dispatchEvent(
            new CustomEvent('section-animation-leave', {
              detail: { section: this.currentSection },
            }),
          );
        }

        this.currentSection = section;
        this.isScrolling = true;

        // Depending on the direction of the scroll, we need to scroll to the top or bottom of the section
        if (!isInitial) {
          window.scrollTo({
            top:
              scrollTop +
              (scrollingDown
                ? rect.top - headerHeight
                : rect.top + rect.height - viewportHeight),
            behavior: 'smooth',
          });
        }

        document.dispatchEvent(
          new CustomEvent('section-animation-enter', {
            detail: { section },
          }),
        );

        setTimeout(() => {
          this.isScrolling = false;
        }, 100);
      }
    });

    this.lastScrollY = scrollTop;
  }

  setupDefaultAnimation() {
    const sections = document.querySelectorAll('.js-animated-section');
    // Setup default animation for sections
    sections.forEach((section) => {
      const content = section.querySelector('.js-animated-section-content');
      const bg = section.querySelector('.js-animated-section-bg');

      animate(content, {
        opacity: 0,
      });
      animate(bg, {
        opacity: 0,
      });
    });
  }
}

// Initialize
new ScrollSnap();

document.addEventListener('DOMContentLoaded', function () {
  // SECTION ANIMATION
  document.addEventListener('section-animation-enter', function (e) {
    const content = e?.detail?.section?.querySelector(
      '.js-animated-section-content',
    );
    const bg = e?.detail?.section?.querySelector('.js-animated-section-bg');

    animate(
      content,
      {
        opacity: 1,
        y: [-30, 0],
      },
      {
        duration: 0.3,
        delay: 0.4,
      },
    );
    animate(
      bg,
      {
        opacity: 1,
      },
      {
        duration: 0.5,
      },
    );
  });

  document.addEventListener('section-animation-leave', function (e) {
    const content = e?.detail?.section?.querySelector(
      '.js-animated-section-content',
    );
    const bg = e?.detail?.section?.querySelector('.js-animated-section-bg');

    animate(content, { opacity: 0, y: [0, -30] });
    animate(bg, { opacity: 0 });
  });

  // VARIOUS ANIMATIONS
  document.addEventListener('section-animation-enter', function (e) {
    const animatedItems =
      e?.detail?.section?.querySelectorAll('[data-animate]');
    if (!animatedItems) return;

    animatedItems.forEach((item) => {
      const animateEnter = item?.getAttribute('data-animate-enter');
      const animateDuration =
        item?.getAttribute('data-animate-duration') ?? 0.3;
      const animationDelay = item?.getAttribute('data-animate-delay') ?? 0.5;

      if (!animateEnter) return;

      inView(
        item,
        (element, info) => {
          animate(element, JSON.parse(animateEnter), {
            visualDuration: +animateDuration,
            delay: +animationDelay,
          });
        },
        {
          amount: 0.4,
        },
      );
    });
  });

  document.addEventListener('section-animation-leave', function (e) {
    const animatedItems =
      e?.detail?.section?.querySelectorAll('[data-animate]');
    if (!animatedItems) return;

    animatedItems.forEach((item) => {
      const animateLeave = item?.getAttribute('data-animate-leave');
      const animateDuration =
        item?.getAttribute('data-animate-duration') ?? 0.3;
      const animationDelay = item?.getAttribute('data-animate-delay') ?? 0.5;

      if (!animateLeave) return;

      animate(item, JSON.parse(animateLeave), {
        visualDuration: animateDuration,
        delay: +animationDelay,
      });
    });
  });

  // BLOG POSTS STAGGERED ANIMATION
  // if not in intersection observer, set default opacity
  const blogPosts = document.querySelectorAll('.home .post-card');
  blogPosts.forEach((post) => {
    const rect = post.getBoundingClientRect();
    const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (!isInViewport) {
      animate(post, {
        opacity: 0,
      });
    }
  });

  document.addEventListener('section-animation-enter', function (e) {
    const blogPosts = e?.detail?.section?.querySelectorAll('.home .post-card');
    if (blogPosts.length > 0) {
      blogPosts.forEach((post, index) => {
        inView(
          post,
          (element, info) => {
            animate(
              element,
              {
                opacity: [0, 1],
              },
              {
                delay: index * 0.25,
                duration: 0.3,
              },
            );
          },
          {
            amount: 0.3,
          },
        );
      });
    }

    const btn = e?.detail?.section?.querySelector('.btn--bounced');
    if (btn) {
      inView(
        btn,
        (element, info) => {
          animate(
            element,
            {
              opacity: [0, 1],
            },
            {
              delay: blogPosts.length * 0.3,
            },
          );
        },
        {
          amount: 0.5,
        },
      );
    }
  });

  document.addEventListener('section-animation-leave', function (e) {
    const blogPosts = e?.detail?.section?.querySelectorAll('.home .post-card');
    if (blogPosts.length > 0) {
      blogPosts.forEach((post) => {
        animate(post, {
          opacity: 0,
        });
      });
    }
  });

  // ACCORDION TEAM CARDS ANIMATION
  // Set initial animation state for Team cards
  const accordions = document.querySelectorAll('.accordion-content');
  accordions.forEach((accordion) => {
    if (!accordion.classList.contains('is-expanded')) {
      const accordionContentItems = accordion.querySelectorAll('.team-card');
      const btn = accordion.querySelector('.btn');
      if (accordionContentItems.length > 0) {
        accordionContentItems.forEach((item) => {
          animate(item, {
            opacity: [0, 1],
          });
        });
      }

      if (btn) {
        animate(btn, {
          opacity: [0, 1],
        });
      }
    }
  });

  // Animate Team cards when accordion is expanded
  document.addEventListener('accordion-toggle', function (e) {
    const accordionContentItems =
      e?.detail?.accordionContent?.querySelectorAll('.team-card');
    const btn = e?.detail?.accordionContent?.querySelector('.btn');

    if (accordionContentItems.length === 0) return;

    if (e?.detail?.expanded) {
      accordionContentItems.forEach((item, index) => {
        // Show items one by one
        animate(
          item,
          {
            opacity: [0, 1],
          },
          {
            delay: index * 0.25, // delay between each item
          },
        );
      });

      if (btn) {
        animate(
          btn,
          {
            opacity: [0, 1],
          },
          {
            delay: accordionContentItems.length * 0.25, // delay to show last
          },
        );
      }
    } else {
      accordionContentItems.forEach((item) => {
        animate(item, {
          opacity: 0,
        });
      });

      if (btn) {
        animate(btn, {
          opacity: 0,
        });
      }
    }
  });

  // CONTACT ANIMATION
  const contactItems = document.querySelectorAll('.js-animated-contact-item');
  if (contactItems.length > 0) {
    contactItems?.forEach((item, index) => {
      // Show items one by one when first item comes into view
      inView(contactItems[0], (element, info) => {
        animate(
          item,
          {
            y: [-10, 0],
            opacity: [0, 1],
          },
          {
            delay: index * 0.3,
            duration: 0.3,
          },
        );

        return () => {
          animate(item, {
            y: [0, -10],
            opacity: 0,
          });
        };
      });
    });
  }

  // MENU ANIMATION
  const menuItems = document.querySelectorAll('.js-animated-menu-nav-item');
  if (menuItems.length > 0) {
    menuItems?.forEach((item, index) => {
      // Show items one by one when first item comes into view
      inView(menuItems[0], (element, info) => {
        animate(
          item,
          {
            y: [-10, 0],
            opacity: [0, 1],
          },
          {
            delay: index * 0.25,
            duration: 0.25,
          },
        );

        return () => {
          animate(item, {
            y: [0, -10],
            opacity: 0,
          });
        };
      });
    });
  }

  const menuAcrossLogo = document.querySelector(
    '.js-animated-menu-across-logo img',
  );
  if (menuAcrossLogo) {
    inView(menuAcrossLogo, (element, info) => {
      animate(
        element,
        {
          opacity: [0, 1],
          y: [50, 0],
        },
        {
          delay: 0.5,
        },
      );

      return () => {
        animate(element, {
          opacity: 0,
        });
      };
    });
  }

  // SECTION ANIMATION
  // const sectionTransitionDuration = 0.5;

  // sections.forEach((item, index) => {
  //   // const content = item.querySelector('.js-animated-section-content');
  //   // const bg = item.querySelector('.js-animated-section-bg');
  //   // const isLastSection = index === sections.length - 1;

  //   // inView(
  //   //   item,
  //   //   (element, enterInfo) => {
  //   //     // animate(content, { opacity: 1 });
  //   //     // animate(bg, { opacity: 1 });
  //   //     // console.log(enterInfo);
  //   //     // This will fire when the element leaves the viewport
  //   //     return (leaveInfo) => {
  //   //       animate(content, { opacity: 0 });
  //   //       animate(bg, { opacity: 0 });
  //   //       console.log(leaveInfo);
  //   //     };
  //   //   },
  //   //   { amount: 0.6 },
  //   // );

  //   // scroll(
  //   //   animate(bg, {
  //   //     // y: ['-30%', '0%', '0%', '-30%'],
  //   //     opacity: [0, 1, 1, 0],
  //   //     // zIndex: [0, 0, 1, 1, 0, 0],
  //   //   }),
  //   //   {
  //   //     target: item,
  //   //     offset: ['start end', 'start center', 'end center', 'end start'],
  //   //   },
  //   // );

  //   // const animateXenter = item.querySelectorAll('[data-animate-x]');

  //   // scroll(
  //   //   throttle((progress, info) => {
  //   //     // console.log(info);
  //   //     if (progress > 0 && progress < 1) {
  //   //       animate(
  //   //         content,
  //   //         {
  //   //           opacity: 1,
  //   //         },
  //   //         {
  //   //           visualDuration: sectionTransitionDuration,
  //   //         },
  //   //       );
  //   //       animate(
  //   //         bg,
  //   //         {
  //   //           opacity: 1,
  //   //         },
  //   //         {
  //   //           visualDuration: sectionTransitionDuration,
  //   //         },
  //   //       );

  //   //       for (const item of animateXenter) {
  //   //         animate(
  //   //           item,
  //   //           {
  //   //             x: 0, // Default value
  //   //           },
  //   //           {
  //   //             visualDuration: sectionTransitionDuration,
  //   //           },
  //   //         );
  //   //       }

  //   //       // create trigger for "section-animation-enter"
  //   //       // document.dispatchEvent(
  //   //       //   new CustomEvent('section-animation-enter', {
  //   //       //     detail: {
  //   //       //       section: item,
  //   //       //     },
  //   //       //   }),
  //   //       // );
  //   //     } else {
  //   //       // console.log(progress);
  //   //       // if scrolling down, don't animate
  //   //       // if (progress > 1) {
  //   //       //   return;
  //   //       // }

  //   //       // console.log('no end animation', isLastSection);
  //   //       // console.log(item);

  //   //       if (isLastSection && progress === 1) {
  //   //         return;
  //   //       }

  //   //       // if (!isLastSection && progress !== 1) {
  //   //       const contentAnimation = animate(
  //   //         content,
  //   //         {
  //   //           opacity: 0.1,
  //   //         },
  //   //         {
  //   //           visualDuration: sectionTransitionDuration,
  //   //         },
  //   //       );
  //   //       const bgAnimation = animate(
  //   //         bg,
  //   //         {
  //   //           opacity: 0,
  //   //         },
  //   //         {
  //   //           visualDuration: sectionTransitionDuration,
  //   //         },
  //   //       );

  //   //       // pause animation when on last section

  //   //       // contentAnimation.pause();
  //   //       // bgAnimation.pause();
  //   //       for (const item of animateXenter) {
  //   //         const animateX = item.getAttribute('data-animate-x');
  //   //         animate(
  //   //           item,
  //   //           {
  //   //             x: +animateX,
  //   //           },
  //   //           {
  //   //             visualDuration: sectionTransitionDuration,
  //   //           },
  //   //         );
  //   //       }

  //   //       // create trigger for "section-animation-leave"
  //   //       // document.dispatchEvent(
  //   //       //   new CustomEvent('section-animation-leave', {
  //   //       //     detail: {
  //   //       //       section: item,
  //   //       //     },
  //   //       //   }),
  //   //       // );
  //   //     }
  //   //     // }
  //   //   }, 100),
  //   //   {
  //   //     target: item,
  //   //     offset: ['start 35%', 'end 35%'],
  //   //   },
  //   // );
  // });
});
