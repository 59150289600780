import { debounce } from '@webentorCore/_utils';

document.addEventListener('alpine:init', () => {
  Alpine.data('headerScroll', () => ({
    openMenu: false,
    isScrolled: false,
    lastScrollTop: 0,
    isHidden: false,
    headerHeight: 0,

    init() {
      this.headerHeight = this.$refs.header.offsetHeight;

      // Add padding to body to prevent jump
      // document.body.style.paddingTop = `${this.headerHeight}px`;

      // Debounced scroll handler
      const debouncedScroll = debounce(() => this.handleScroll(), 30);
      window.addEventListener('scroll', debouncedScroll);

      // Debounced resize handler
      const debouncedResize = debounce(() => {
        this.headerHeight = this.$refs.header.offsetHeight;
        // document.body.style.paddingTop = `${this.headerHeight}px`;
      }, 250);
      window.addEventListener('resize', debouncedResize);
    },

    handleScroll() {
      // Don't hide header if menu is open
      if (this.openMenu) {
        this.isHidden = false;
        return;
      }

      if (window.scrollY > this.headerHeight) {
        this.isHidden = window.scrollY > this.lastScrollTop;
      } else {
        this.isHidden = false;
      }

      if (window.scrollY) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
      this.lastScrollTop = window.scrollY;
    },
  }));
});
