import './_header';
import './_animation';
import './_mapbox';
import './_forms';

import Lightbox from './_lightbox';

// Register Alpine last to ensure all extensions are registered
import './_alpine';

// Register static assets
import.meta.glob(['../images/**', '../fonts/**']);

new Lightbox();
