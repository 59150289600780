import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

const mapDiv = document.querySelector('#contact-map');

if (mapDiv) {
  mapboxgl.accessToken =
    'pk.eyJ1IjoiYWNyb3NzLW1hcmtldGluZyIsImEiOiJjbThlOXNqMjcyMng4MmtzNTc1MHF6OTVuIn0.blrzbFmF363-O4w9vy3mOg';

  const map = new mapboxgl.Map({
    container: 'contact-map',
    style: 'mapbox://styles/mapbox/light-v11',
    center: [17.10247, 48.14585],
    zoom: 17,
  });

  new mapboxgl.Marker({
    color: '#7E6098',
  })
    .setLngLat([17.10247, 48.14585])
    .addTo(map);
}
