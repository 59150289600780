import Choices from 'choices.js';

// Custom select for consultants
document.addEventListener('DOMContentLoaded', function () {
  const element = document.querySelector('.team-select .gfield_select');

  if (!element) return;

  const choices = new Choices(element, {
    removeItemButton: true,
    itemSelectText: '',
    searchEnabled: false,
    removeItems: false,
    callbackOnCreateTemplates: function (
      template,
      escapeForTemplate,
      getClassNames,
    ) {
      return {
        choice: ({ classNames }, data) => {
          const img = data?.element?.dataset?.img;
          const label = data?.placeholder ? '-' : data.label;

          return template(`
                  <div class="${getClassNames(classNames.item).join(' ')} ${getClassNames(classNames.itemChoice).join(' ')} ${getClassNames(
                    data.disabled
                      ? classNames.itemDisabled
                      : classNames.itemSelectable,
                  ).join(
                    ' ',
                  )}" data-select-text="${this.config.itemSelectText}" data-choice ${
                    data.disabled
                      ? 'data-choice-disabled aria-disabled="true"'
                      : 'data-choice-selectable'
                  } data-id="${data.id}" data-value="${data.value}" ${
                    data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                  }>
                      <div class="flex items-center gap-3">
                          <span class="w-10 h-9">
                              ${img ? `<img src="${img}" class="w-full h-full object-cover radius-box-author" />` : ''}
                          </span>
                          <span>${label}</span>
                      </div>
                  </div>
                  `);
        },
      };
    },
  });
});
